<!--
File: TranslateEditForm.vue
Description: form for adding/editing a single translation.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <BaseDropdown :class="getClass('category')" :label="$t('translate.category')" v-model="category"
            :items="getTranslationCategories" data-vv-name="category" v-validate="modelValidations.category"
            :isRequired="true" required />
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('key')">
            <label for="key">{{ $t('translate.key') }}</label>
            <md-input id="key" v-model="key" type="text" data-vv-name="key" v-validate="modelValidations.key" required />
          </md-field>
        </div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('en')">
          <label for="en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="en" v-model="en" type="text" data-vv-name="en" v-validate="modelValidations.en" required />
        </md-field>

        <md-field :class="getClass('ru')">
          <label for="ru">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="ru" v-model="ru" type="text" data-vv-name="ru" v-validate="modelValidations.ru" required />
        </md-field>

        <md-field :class="getClass('kg')">
          <label for="kg">{{ $t('stdCols.name_kg') }}</label>
          <md-input id="kg" v-model="kg" type="text" data-vv-name="kg" v-validate="modelValidations.kg" required />
        </md-field>
      </div>
    </template>
  </modal>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { Modal, BaseDropdown } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'

  export default {
    name: 'translations-edit-form',
    mixins: [permissions, messages],

    data() {
      return {
        formName: 'TranslateEditForm',
        eligible: false,

        category: null,
        key: null,
        en: null,
        ru: null,
        kg: null,

        initialFormState: null,

        modelValidations: {
          category: { required: true, min: 3 },
          key: { required: true, min: 3 },
          en: { required: true, min: 1 },
          ru: { required: true, min: 1 },
          kg: { required: true, min: 1 },
        }
      }
    },

    props: {
      translationItem: null
    },

    components: {
      Modal,
      BaseDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.$emit('close')
        return
      };

      if (this.translationItem) {
        // Load data on existing treatment
        this.category = this.translationItem.category
        this.key = this.translationItem.key
        this.en = this.translationItem.en
        this.ru = this.translationItem.ru
        this.kg = this.translationItem.kg
      }

      this.$nextTick(() => {
        this.saveInitialState()
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        addTranslation: 'ADD_TRANSLATE_ENTRY',
        editTranslation: 'UPD_TRANSLATE_ENTRY',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const translation = {
          category: this.category,
          key: this.key,
          en: this.en,
          ru: this.ru,
          kg: this.kg
        }

        let errDesc = '';
        let newId;
        const action = !this.translationItem ? this.addTranslation : this.editTranslation;
        const payload = !this.translationItem ? translation : { id: this.translationItem.id, item: translation };
        try {
          const res = await action(payload)
          newId = res?.id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close')
        await this.savedMessage(errDesc, this.$t('route.translate'), this.ru, this.en)
        this.highlightRow(newId)
      },

      saveInitialState() {
        this.initialFormState = {
          category: this.category,
          key: this.key,
          en: this.en,
          ru: this.ru,
          kg: this.kg
        }
      },

    },

    computed: {
      ...mapGetters(['getTranslationCategories']),

      screenTitle() {
        return this.translationItem ? this.$t('screen_titles.translate_upd') : this.$t('screen_titles.translate_add')
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.category !== this.initialFormState.category ||
          this.key !== this.initialFormState.key ||
          this.en !== this.initialFormState.en ||
          this.ru !== this.initialFormState.ru ||
          this.kg !== this.initialFormState.kg
      }
    }
  }
</script>